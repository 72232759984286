import { memoize, MemoizedFunction } from 'lodash'
import type { AxiosInstance } from 'axios'
import type { Context } from '@nuxt/types'

const URL_REGEX = /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_\$])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi
const EMAIL_REGEX = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi
const SHORTENED_URL_LENGTH = 24
const MESSAGE_LIMIT = 400
const SHORTENER_URLS = [
  'g-o.io',
  'l-ink.io',
  'short-r.com',
  'u-p.io',
  'u-rl.io',
  'api.emissary.ai',
  (<string> import.meta.env.VITE_API_ENDPOINT)?.replace('https://', '').replace('/', '') || ''
].filter(value => value)

interface IUrlShortenerService {
  $axios: AxiosInstance
  getShortenedUrl: ((url: string) => Promise<{ url: string, shortenedUrl: string }>) & MemoizedFunction
  formatMessageUrls: (
    message: string,
    urlsToSkip: Array<string>
  ) => Promise<string>
  newMessageWouldExceedLimit: (message: string, urls: Array<string>) => boolean
  areAllUrlsSkipable: (message: string, urlsToSkip: Array<string>) => boolean
  shouldSkipUrl: (url: string, urlsToSkip: Array<string>) => boolean
  isAlreadyShortenUrl: (url: string) => boolean;
  urlsInMessage: (message: string) => Array<string>
  shortenUrl: (url: string) => Promise<{ url: string; shortenedUrl: string }>
}

interface ExtendedContext extends Context {
  $axios: AxiosInstance
}

export default class UrlShortenerService implements IUrlShortenerService {
  $axios: AxiosInstance
  getShortenedUrl: ((url: string) => Promise<{ url: string, shortenedUrl: string }>) & MemoizedFunction

  constructor (context: ExtendedContext) {
    this.$axios = context.$axios
    this.getShortenedUrl = memoize(this.shortenUrl)
  }

  public async formatMessageUrls (message: string, urlsToSkip: string[]) {
    const urls = this.urlsInMessage(message)
      .filter((url: string) => !this.shouldSkipUrl(url, urlsToSkip))
    if (this.newMessageWouldExceedLimit(message, urls)) {
      return message
    }
    const shortenedUrlPromises = urls.map((url: string) => this.getShortenedUrl(url))
    const shortenedUrls = await Promise.all(shortenedUrlPromises)
    shortenedUrls.forEach(({ url, shortenedUrl }) => {
      message = message.replace(url, shortenedUrl)
      urlsToSkip.push(shortenedUrl)
    })
    return message
  }

  public newMessageWouldExceedLimit (message: string, urls: Array<string>) {
    const urlsLength = urls.reduce((acc, url) => acc + url.length, 0)
    const newUrlsLength = urls.length * SHORTENED_URL_LENGTH
    return (message.length + (newUrlsLength - urlsLength)) > MESSAGE_LIMIT
  }

  public areAllUrlsSkipable (message: string, urlsToSkip: Array<string>) {
    const urls = this.urlsInMessage(message)
    return urls.every((url: string) => this.shouldSkipUrl(url, urlsToSkip))
  }

  public shouldSkipUrl (url: string, urlsToSkip: Array<string>) {
    const hasPath = (str: string) => str.replace('http://', '')
      .replace('https://', '')
      .replace(/\/$/, '').includes('/')
    return urlsToSkip.includes(url) || this.isAlreadyShortenUrl(url) || !hasPath(url)
  }

  public isAlreadyShortenUrl (url: string) {
    return SHORTENER_URLS.some(shortenerUrl => url.includes(shortenerUrl))
  }

  public urlsInMessage (message: string) {
    const messageWithoutEmails = message.replace(EMAIL_REGEX, '')
    const urls = messageWithoutEmails.match(URL_REGEX)
    return urls || []
  }

  public async shortenUrl (url: string) {
    const returnedUrl = (await this.$axios.post('/api/shorten', { url })).data
    const shortenedUrl = returnedUrl.replace('https://', '')
    return { url, shortenedUrl }
  }
}
